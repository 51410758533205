
import { defineComponent, computed, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useBus } from '@/bus';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { VueCookieNext } from "vue-cookie-next";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { format } from 'date-fns-tz';
export default defineComponent({
name: "Approve Task",
components: {
  Form,
  Field,
  ErrorMessage,
  Datatable,
},
data() {
  return {
    loading: false,
    taskList: [] as any,
    initData:[] as any,
    taskKeyUpdate: 0,
    search: '',
    componentKey:0,
    user:[] as any,
    employee_id: '' as any,
    role_id:'' as any,
    all_cookies: '' as any,
    qc_type:'' as any,
    current_status:'' as any,
    taskDetailData: [] as any,
    date: new Date(),
    tableHeader: [
      {
        name: 'TASK No.',
        key: 'index',
      },
      {
        name: 'DISTRICT',
        key: 'district_name',
      },
      {
        name: 'UPAZILLA',
        key: 'upazila_name',
      },
      {
        name: 'GRID',
        key: 'grid_name',
      },
      {
        name: 'Mouza',
        key: 'mouza_name',
      },
      {
        name: 'DEADLINE',
        key: 'deadline',
      },
      {
        name: 'ASSIGNED TO',
        key: 'assigned_to',
      },
      {
        name: 'ASSIGNED BY',
        key: 'assigned_by',
      },
      {
        name: 'ASSIGNING DATE',
        key: 'assigning_date',
      },
      {
        name: 'FIRST UPLOADING DATE',
        key: 'first_uploading_date',
      },
      {
        name: 'LAST MODIFICATION DATE',
        key: 'last_modification_date',
      },
      {
        name: 'CONFIRMATION DATE',
        key: 'confirmation_date',
      },
      {
        name: 'Actions',
        key: 'actions',
      },
    ]
  };
},
async created() {
  await this.getTaskList();
  this.current_status = this.taskList?.current_status;
  this.initData= this.taskList;
},
methods: {
  formatDate(dateString) {
      return dateString ? format(new Date(dateString), 'dd-MMM-yyyy') : '';
    },
  async getTaskList() {
    this.loading=true;
    this.user= VueCookieNext.getCookie("_seip_employee_id");
    this.employee_id= VueCookieNext.getCookie("_seip_employee_id");
    this.role_id= VueCookieNext.getCookie("_seip_role_id");
    await ApiService.get("configurations/task/final?employee_id="+ this.employee_id + "&role_id="+this.role_id + "&current_status=3" +"&task_type=8")
      .then((response) => {
        this.taskList = response.data.data;
        console.log(this.taskList);
        this.taskKeyUpdate+=1;
        this.loading=false
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  async approve() { 
      let formData = new FormData();
      formData.set('task_detail_id', '27');     
      this.loading = true;
      await ApiService.post('configurations/task/approve',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            Swal.fire({
                text:  'Approve Successfully.',
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                confirmButton: "btn fw-semobold btn-success",
              },
            }).then(() => {
              this.$router.push('/dash');
            });
          }
          else
          {
            Swal.fire({
                text:  'Something Went Wrong!',
                icon: "warning",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-danger",
              },
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
  taskView(taskDetail){
    const { bus } = useBus();
    let value = {
      taskDetail: taskDetail,
    };
    bus.emit('task-data-view', value);
  },
  assignQc(taskDetail){
    const { bus } = useBus();
    let value = {
      taskDetail: taskDetail,
    };
    bus.emit('task-data-assign', value);
  },
  taskEdit(task){
    const { bus } = useBus();
    let value = {
      task: task,
    };
    bus.emit('task-data-edit', value);
  },
  taskUpload(task){
    const { bus } = useBus();
    let value = {
      task: task,
    };
    bus.emit('task-data-upload', value);
  },

  filterTasks() {
    if(this.search=='')
    {
      this.taskList= this.initData;
      this.taskKeyUpdate +=1;
    }
    const tasks = this.taskList.filter(task => {
      return (
        task.deadline.toLowerCase().includes(this.search.toLowerCase()) ||
        task?.upazila_info?.upazila_name_eng.toLowerCase().includes(this.search.toLowerCase()) ||
        task?.grid_info?.grid_name.toLowerCase().includes(this.search.toLowerCase()) ||
        task?.employee_info?.name.toLowerCase().includes(this.search.toLowerCase())
      );
    });

    this.taskList= tasks;
    this.taskKeyUpdate +=1;
  },
},
});
